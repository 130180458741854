import React from "react";
import ContentNormal from "../../components/ContentNormal";

const HomePage = () => {
    return (
        <div>
            <ContentNormal />
        </div>
    );
};

export default HomePage;
