import React from "react";
import "./style.scss"
import ManaProduct from "../../components/ManaProduct";
const ManaProductPage = () => {
    return (
        <>
            <ManaProduct />
        </>
    )
}
export default ManaProductPage